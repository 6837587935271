//导入vue构造函数
import Vue from 'vue'
//导入根组件App.vue
import App from './App.vue'
//导入路由文件
import router from './router'
// 引入store
import store from './store'
import './plugins/fullpage.js'
// 引入css文件
import '@/common/font/font.css'
import animate from 'animate.css';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import { https } from '@/utils/js/https.js'
import 'swiper/dist/css/swiper.css'
Vue.use(animate)
Vue.use(VueAwesomeSwiper)

Vue.prototype.$http = https;
import banner from './mobile/components/banner.vue'
// 引入组件banner
Vue.use(banner)
// 全局注册 定义组件 vue.compnent('组件名称','组件配置')
Vue.component('banner', banner)

// import {mixins} from XXXX 用对象的方式导入  ------  import mixins from XXXX 用变量的方式导入
import {mixins} from '@/plugins/mixins.js';
Vue.mixin(mixins)

import foot from './mobile/components/footer.vue'
Vue.use(foot)
Vue.component('foot', foot)

import partTitle from './mobile/components/title.vue'
Vue.use(partTitle)
Vue.component('partTitle', partTitle)

   //注册'v-class'  当元素出现在可视范围时添加类名出发样式
Vue.directive('class', {
    inserted: function (el, binding) {
 // 聚焦元素
 binding.addClass = () => {
   const { top } = el.getBoundingClientRect()
   const h = document.documentElement.clientHeight || document.body.clientHeight
   if (top < h) {  //减去200刚好，否则刚露头就执行
     el.className = binding.value;
     if (binding.addClass) {
       window.removeEventListener('scroll', binding.addClass)
     }
   }
 }

 window.addEventListener('scroll', binding.addClass,true)

 binding.addClass()
},
unbind: function (el, binding) {
 if (binding.addClass) {
   window.removeEventListener('scroll', binding.addClass)
 }
}
})

// 是否要在生产环境当中给予提示功能
Vue.config.productionTip = false;

// 创建的vue实例
new Vue({
  // el:"#app", =》 配置el属性
  router,
  // 传递给vue实例
  store,
  // render函数是vue通过js渲染dom结构的函数createElement
  // createElement 是 Vue.js 里面的 函数，这个函数的作用就是生成一个 VNode节点（虚拟节点），render 函数得到这个 VNode 节点之后，返回给 Vue.js 的 mount 函数，渲染成真实 DOM 节点，并挂载到根节点上。
  // render: function (createElement) {
  //   return createElement(App);
  // }
  render: h => h(App)
  // render: h => h(App)是ES6的写法
}).$mount('#app')
 // 如果没有配置el属性，可以使用.$mount('#app')进行手动挂载
//  该方法是直接挂载到入口文件index.html 的 id=app 的dom 元素上的
