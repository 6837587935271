<template>
  <div id="app">
    <lay-out></lay-out>
  </div>
</template>

<script>
// 禁止ios10以上点击缩放
window.onload = function () {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}
import LayOut from '@/components/layout.vue';
import { getLocalStorage, removeLocalStorage } from '@/utils/js/tools.js'
export default {
  name: "App",
  components: { LayOut },
  data() {
    return {

    };
  },
  created() {
    document.write(unescape("%3Cspan style='display:none' id='cnzz_stat_icon_1280674458'%3E%3C/span%3E%3Cscript src='https://s4.cnzz.com/z_stat.php%3Fid%3D1280674458%26show%3Dpic' type='text/javascript'%3E%3C/script%3E"));
  }
};
</script>
<style lang="less" scoped>
@import url('../static/css/main.css');

#app {
  // filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
  // -webkit-filter: grayscale(100%);
}
</style>