import Vue from 'vue'
import VueRouter from 'vue-router'
import { setLocalStorage } from '@/utils/js/tools.js'

/** 解决跳转重复路由报错问题 */
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    meta: {
      active: 'index'
    },
    component: () => import('@/views/index.vue')
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      active: 'index'
    },
    component: () => import('@/views/index.vue')
  },
  {
    path: '/travelService',
    name: 'pctravelService',
    meta: {
      active: 'travelService'
    },
    component: () => import('@/views/travelService.vue'),
    children: [
      {
        path: '/travelService/express',
        name: 'pcexpress',
        meta: {
          active: 'travelService',
          ac: 'ex'
        },
        component: () => import('@/views/service/express.vue')
      },
      {
        path: '/travelService/hitchRide',
        name: 'pchitchRide',
        meta: {
          active: 'travelService',
          ac: 'hi'
        },
        component: () => import('@/views/service/hitchRide.vue')
      },
      {
        path: '/travelService/voiceTaxi',
        name: 'pcvoiceTaxi',
        meta: {
          active: 'travelService',
          ac: 'vo'
        },
        component: () => import('@/views/service/voiceTaxi.vue')
      },
      {
        path: '/travelService/businessCar',
        name: 'pcbusinessCar',
        meta: {
          active: 'travelService',
          ac: 'bs'
        },
        component: () => import('@/views/service/businessCar.vue')
      }
    ]
  },
  {
    path: '/aboutSelf',
    name: 'pcaboutSelf',
    meta: {
      active: 'aboutSelf'
    },
    component: () => import('@/views/about/index.vue'),
    children: [
      {
        path: '/aboutSelf/companyProfile',
        name: 'pccompanyProfile',
        meta: {
          active: 'aboutSelf',
          ac: 'jj'
        },
        component: () => import('@/views/about/companyProfile.vue')
      },
      {
        path: '/aboutSelf/companyPartyBuilding',
        name: 'pccompanyPartyBuilding',
        meta: {
          active: 'aboutSelf',
          ac: 'dj'
        },
        component: () => import('@/views/about/companyPartyBuilding.vue')
      },
      {
        path: '/aboutSelf/message',
        name: 'pcmessage',
        meta: {
          active: 'aboutSelf',
          ac: 'jy'
        },
        component: () => import('@/views/about/message.vue')
      },
      {
        path: '/aboutSelf/social',
        name: 'pcsocial',
        meta: {
          active: 'aboutSelf',
          ac: 'zr'
        },
        component: () => import('@/views/about/social.vue'),
      },
      // PC端 企业详情 子页面
      {
        path: '/aboutSelf/social/slect1',
        name: 'slect1',
        component: () => import('@/views/about/slect1.vue')
      },
      {
        path: '/aboutSelf/social/slect2',
        name: 'slect2',
        component: () => import('@/views/about/slect2.vue')
      }, {
        path: '/aboutSelf/social/slect3',
        name: 'slect3',
        component: () => import('@/views/about/slect3.vue')
      },
      {
        path: '/aboutSelf/social/slect4',
        name: 'slect4',
        component: () => import('@/views/about/slect4.vue')
      },
      {
        path: '/aboutSelf/contact',
        name: 'pccontact',
        meta: {
          active: 'aboutSelf',
          ac: 'lx'
        },
        component: () => import('@/views/about/contact.vue')
      },
    ]
  },
  {
    path: '/driverRecruitment',
    name: 'pcdriverRecruitment',
    meta: {
      active: 'driverRecruitment'
    },
    component: () => import('@/views/driverRecruitment.vue')
  },

  // 手机端
  {
    path: '/mobile/index',
    name: 'mobileIndex',
    component: () => import('@/mobile/index.vue')
  },
  {
    path: '/mobile/driverRecruit',
    name: 'driverRecruit',
    component: () => import('@/mobile/driverRecruit.vue')
  },
  {
    path: '/mobile/service/express',
    name: 'express',
    component: () => import('@/mobile/service/express.vue')
  },
  {
    path: '/mobile/service/hitchRide',
    name: 'hitchRide',
    component: () => import('@/mobile/service/hitchRide.vue')
  },
  {
    path: '/mobile/service/businessCar',
    name: 'businessCar',
    component: () => import('@/mobile/service/businessCar.vue')
  },
  {
    path: '/mobile/service/vioceTaxi',
    name: 'vioceTaxi',
    component: () => import('@/mobile/service/vioceTaxi.vue')
  },
  // 手机端 企业详情
  {
    path: '/mobile/ourself/duty1',
    name: 'duty1',
    component: () => import('@/mobile/ourself/duty1.vue')
  },
  {
    path: '/mobile/ourself/duty1/slect1',
    name: 'slect11',
    component: () => import('@/mobile/ourself/slect1.vue')
  },
  {
    path: '/mobile/ourself/duty1/slect2',
    name: 'slect22',
    component: () => import('@/mobile/ourself/slect2.vue')
  },
  {
    path: '/mobile/ourself/duty1/slect3',
    name: 'slect33',
    component: () => import('@/mobile/ourself/slect3.vue')
  },
  {
    path: '/mobile/ourself/duty1/slect4',
    name: 'slect44',
    component: () => import('@/mobile/ourself/slect4.vue')
  },
  {
    path: '/mobile/ourself/message1',
    name: 'message1',
    component: () => import('@/mobile/ourself/message1.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to, from) => {
  window.scrollTo(0, 0);
})

export default router
